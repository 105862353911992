@import 'src/styles/variables';
@import 'src/styles/mixins';

$cardWidth: 300px;

.formNavigation {
  @include sm {
    width: 900px;
    max-width: 900px;
  }
}

.formContainer {
  @include sm {
    padding: 0;
    width: 900px;
    max-width: 900px;
  }
}

.container {
  @include sm {
    .contentWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leftContent {
        width: 45%;
      }

      .previewContainer {
        width: 414px;
        height: 210px;
        margin-bottom: 32px;
      }
    }

    .cardPickerItems {
      justify-content: space-between;
    }

    .buttonContainer {
      margin-top: 32px;
    }
  }
}

.title {
  @include body-text('lg', 700);
  font-size: 30px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 32px;
}

.inputs__blue,
.inputs__dark,
.inputs__purple {
  .input::placeholder {
    color: $black10;
  }
}

.inputs__default {
  .input::placeholder {
    color: $black40;
  }
}

.inputContainer {
  margin: 24px 0;
}

.input {
  border-radius: 12px;
  width: 100%;
  height: 48px;
  border: 0;
}

.wholeInput {
  margin: 24px 0;
}

.label {
  margin-bottom: 12px;
}

.cardPickerItems {
  display: flex;
  justify-content: space-between;
}

.cardItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.card {
  cursor: pointer;
  border: 2.5px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 88px;
  height: 48px;
  margin-bottom: 8px;
  -webkit-tap-highlight-color: transparent;

  svg {
    width: 48px;
    border-radius: 12px;
  }
}

.card__blue {
  background-color: $blue300;
}

.card__purple {
  background-color: #e9e3ff;
}

.card__dark {
  background-color: #e9e3ff;
}

.card__default {
  background-color: $black6;
}

.card.selected {
  border: 2.5px solid $black3;
}

.card.selected.card__default {
  border: 2.5px solid $blue500;
}

.card.selected {
  &.card__dark {
    border-color: $deepPurple;
    background-color: $black6;
  }

  &.card__purple {
    border-color: #4c2f8b;
    background-color: $white;
  }
}

.cardColor {
  @include body-text('sm', 400);
}

.previewContainer {
  position: relative;
  z-index: 1;
  max-width: 400px;
  height: 210px;
  margin: 32px auto;
}

.cardPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: $cardWidth;
  margin: 0 auto;
}

.flipCardBackground {
  position: absolute;
  background-color: $black3;
  opacity: 0.15;
  filter: blur(17px);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.flipCard {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: transparent;
  perspective: 1000px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: grab;
  transform-style: preserve-3d;
}

.flipCardInnerBack {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  max-width: $cardWidth;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */

  & > svg {
    width: 100%;
    height: 100%;
    max-width: $cardWidth;
    max-height: 210px;
  }
}

.flipCardBack {
  transform: rotateY(180deg);
}

.cardOwnerName {
  color: $white;
  position: absolute;
  text-transform: uppercase;
  @include body-text('md', 300);

  &.white {
    color: $black80;
  }

  &.purple_silver,
  &.gold,
  &.light_purple {
    font-size: 12px;
    letter-spacing: 1px;
  }

  &.purple_silver {
    color: #d8d8d8;
  }

  &.gold {
    color: #e0ca91;
  }

  &.light_purple {
    color: $white;
  }
}

.frontName {
  right: 8%;
  bottom: 30%;

  &.purple_silver,
  &.gold,
  &.light_purple {
    left: 6%;
    bottom: 13%;

    @include sm {
      left: 7%;
      bottom: 12%;
    }
  }
}

.backName {
  @include body-text('xs', 300);
  left: 7%;
  bottom: 35%;
}

.ellipseContainer {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;

  & > svg {
    width: 100%;
    height: 100%;
  }

  &.blue,
  &.dark {
    .ellipse stop {
      stop-color: $white;
    }

    .handPointingContainer {
      background-color: $white;

      .handPointing path {
        fill: $deepPurple;
      }
    }
  }

  &.purple {
    .ellipse stop {
      stop-color: $white;
    }

    .handPointingContainer {
      background-color: $white;

      .handPointing path {
        fill: $purple500;
      }
    }
  }
}

.handPointingContainer {
  position: absolute;
  bottom: -20px;
  width: 40px;
  height: 40px;
  background: $deepBlue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: $white;
    width: 20px;
    height: 20px;
  }
}

.buttonContainer {
  margin: 100px auto 0;
  max-width: 450px;
}

.cardBackground {
  position: absolute;
  top: -75px;
  right: -40px;
  width: 248px;
  height: 248px;
}

.draggableArea {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  z-index: 2;
  user-select: none;
  -webkit-user-select: none;
}

.draggableArea:active {
  user-select: none;
  -webkit-user-select: none;
}
